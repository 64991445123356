<template>
  <div class="videos one-component-detail-admin">
    <div class="title-component-admin">
      <el-row type="flex" align="middle" justify="space-between">
        <span>Upload Video</span>
        <div>
          <el-button
            type="primary"
            size="small"
            title="Thêm video từ máy tính"
            :loading="loading"
            @click="openUploadVideoPopup(null)"
          >Thêm</el-button>
        </div>
      </el-row>
    </div>
    <div v-if="!videos.length" v-loading="loading" class="empty p-3">
      Chưa có dữ liệu!
    </div>
    <el-row v-else class="list-videos p-3" type="flex">
      <el-card v-for="video in videos" :key="video.id" class="card-video">
        <div class="video" @click="handleShowVideo(video)">
          <img v-if="video.avatar" :src="video.avatar.url" alt="href" />
          <img src="@/assets/images/thumbnail-overlay.png" alt="href" />
        </div>
        <div class="info px-2">
          <p class="mr-2" :title="video.title">{{ video.title }}</p>
          <el-row type="flex" align="middle">
            <i
              class="el-icon-edit text-primary mr-2"
              @click="openUploadVideoPopup(video)"
            ></i>
            <i
              class="el-icon-delete text-danger"
              @click="deleteVideos(video)"
            ></i>
          </el-row>
        </div>
      </el-card>
    </el-row>
    <upload-video-popup
      v-if="uploadVisible"
      :visible="uploadVisible"
      :video="selectedVideo"
      :type="object_type"
      :object="data"
      @reload="loadVideos"
      @close="uploadVisible = false"
    />
    <show-video
      :visible="showVisible"
      :video="selectedVideo"
      @close="showVisible = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { listStorages, deleteStorages, getStorage } from '@/services/storage'
import { getTranslation } from '@/utils/filters'
import UploadVideoPopup from '../popups/UploadVideo'
import ShowVideo from '../popups/ShowVideo.vue'

export default {
  name: 'Videos',
  components: {
    UploadVideoPopup,
    ShowVideo
  },
  props: {
    data: Object,
    type: String
  },
  data() {
    return {
      loading: false,
      videos: [],
      uploadVisible: false,
      showVisible: false,
      selectedVideo: null
    }
  },
  computed: {
    ...mapGetters(['language']),
    object_type() {
      if (['commerce'].includes(this.type)) {
        return 'tour'
      }
      if (['tour', 'post', 'artifact'].includes(this.type)) {
        return this.type
      }
      return 'place'
    }
  },
  beforeMount() {
    this.loadVideos()
  },
  methods: {
    loadVideos() {
      this.loading = true
      const params = {
        object_id: this.data.id,
        object_type: this.object_type,
        file_type: 'video',
        order: 'ordered',
        locale: this.language
      }
      listStorages(params)
        .then(async (res) => {
          this.videos = res.result.map((v) => ({
            ...v,
            title: getTranslation(v, this.language)
              ? getTranslation(v, this.language).title
              : '',
            description: getTranslation(v, this.language)
              ? getTranslation(v, this.language).description
              : ''
          }))
          const requests = []
          this.videos.forEach(v => {
            if (v.avatar_id) {
              requests.push(getStorage({ id: v.avatar_id, locale: this.language }))
            }
          })
          await Promise.all(requests).then((res) => {
            const images = res.map((r) => r.result[0])
            this.videos = this.videos.map((v) => {
              const avatar = images.find((a) => a.id === v.avatar_id)
              return {
                ...v,
                avatar: avatar || null
              }
            })
            this.loading = false
          })
        })
        .catch(() => {
          this.loading = false
        })
    },

    openUploadVideoPopup(video) {
      this.selectedVideo = video
      this.uploadVisible = true
    },

    handleShowVideo(video) {
      this.selectedVideo = video
      this.showVisible = true
    },

    deleteVideos(video) {
      this.$confirm('Bạn có chắc chắn muốn xóa không?', 'Xác nhận', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Đóng',
        type: 'warning'
      }).then(() => {
        this.loading = true
        deleteStorages({
          file_type: 'video',
          object_type: this.object_type,
          ids: video.id
        })
          .then(() => {
            this.videos = this.videos.filter((v) => v.id !== video.id)
            this.loading = false
          })
          .catch(() => {
            this.loading = false
            this.$notify.error({
              title: 'Thông báo',
              message: 'Không thể xóa video!'
            })
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.videos {
  .list-videos {
    justify-content: space-between;
    flex-wrap: wrap;
    .card-video {
      width: 48%;
      box-sizing: border-box;
      margin-bottom: 15px;
      .video {
        width: 100%;
        height: 120px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          position: absolute;
          object-fit: cover;
          cursor: pointer;
        }
      }
      .info {
        width: 100%;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        justify-content: space-between;
        p {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        i {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.videos {
  .el-card__body {
    padding: 0px !important;
  }
}
</style>
