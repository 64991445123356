<template>
  <div class="category one-component-detail-admin">
    <div class="title-component-admin">Thẻ Tags</div>
    <el-form :model="data" class="p-3">
      <el-form-item label="Chọn thẻ tags">
        <el-select
          v-model="data.tag_ids"
          class="w-100"
          filterable
          multiple
          remote
          clearable
          :remote-method="searchTags"
        >
          <el-option
            v-for="c in tags"
            :key="c.id"
            :label="c.name"
            :value="c.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getTags } from '@/services/tag'

export default {
  name: 'Tags',
  props: {
    data: Object,
    type: String
  },
  data() {
    return {
      callingAPI: false,
      searchText: '',
      allTags: []
    }
  },
  computed: {
    ...mapGetters(['language']),
    tags() {
      return this.allTags.filter((tag) => {
        const filtered = tag.name.toLowerCase().includes(this.searchText.toLowerCase())
        let isSameOrg = true
        if (this.data.organization) {
          isSameOrg = this.data.organization.id === tag.organization_id
        } else {
          isSameOrg = !tag.organization_id
        }
        return filtered && isSameOrg
      }).slice(0, 10)
    }
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.callingAPI = true
      getTags({ page: 1, per_page: 1000000, locale: this.language }).then((response) => {
        this.allTags = response.result
        this.callingAPI = false
      }).catch(() => {
        this.allTags = []
        this.callingAPI = false
      })
    },
    searchTags(searchText) {
      this.searchText = searchText
    }
  }
}
</script>

<style lang="scss" scoped>
.category {
  .el-select {
    width: 100%;
  }
}
</style>
