<template>
  <div class="audio one-component-detail-admin">
    <div class="title-component-admin">
      <el-row type="flex" align="middle" justify="space-between">
        <span>Audio</span>
        <el-upload
          accept="audio/*"
          list-type="file"
          action
          :limit="1"
          :show-file-list="false"
          :http-request="uploadAudios"
        >
          <el-button
            type="primary"
            size="small"
            title="Chọn audio từ máy tính của bạn"
            :loading="loading"
          >
            Thêm
          </el-button>
        </el-upload>
      </el-row>
    </div>
    <div v-if="!audios.length" v-loading="loading" class="empty p-3">
      Chưa có dữ liệu!
    </div>
    <div v-else v-loading="loading" class="list-audios p-3" type="flex">
      <el-card
        v-for="(audio, index) in audios"
        :key="audio.id"
        class="card-audio"
        :class="{'mb-2': index < audios.length - 1}"
      >
        <audio controls style="width: 100%">
          <source :src="audio.url" />
        </audio>
        <el-row class="mt-2" type="flex" align="middle" justify="space-between">
          <p class="m-0 mr-2" :title="audio.name">{{ audio.name }}</p>
          <el-row type="flex" align="middle">
            <i
              class="el-icon-edit text-primary mr-2"
              @click="openUploadAudioPopup(audio)"
            ></i>
            <i
              class="el-icon-delete text-danger"
              @click="deleteAudios(audio)"
            ></i>
          </el-row>
        </el-row>
      </el-card>
    </div>
    <update-audio-popup
      v-if="updateVisible"
      :visible="updateVisible"
      :audio="selectedAudio"
      :type="object_type"
      :object="data"
      @reload="loadAudios"
      @close="() => updateVisible = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UpdateAudioPopup from '../popups/UpdateAudio.vue'

import {
  listStorages,
  deleteStorages,
  createStorage
} from '@/services/storage'

export default {
  name: 'Audios',
  components: {
    UpdateAudioPopup
  },
  props: {
    data: Object,
    type: String
  },
  data() {
    return {
      loading: false,
      callingAPI: false,
      audios: [],
      selectedAudio: null,
      updateVisible: false
    }
  },
  computed: {
    ...mapGetters(['language']),
    object_type() {
      if (['commerce'].includes(this.type)) {
        return 'tour'
      }
      if (['tour', 'post', 'artifact'].includes(this.type)) {
        return this.type
      }
      return 'place'
    }
  },
  beforeMount() {
    this.loadAudios()
  },
  methods: {
    loadAudios() {
      this.loading = true
      const params = {
        object_id: this.data.id,
        object_type: this.object_type,
        file_type: 'audio',
        order: 'ordered',
        locale: this.language
      }
      listStorages(params)
        .then((res) => {
          this.audios = res.result
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },

    uploadAudios(fileInfo) {
      const params = {
        file: fileInfo.file,
        objectId: this.data.id,
        objectType: this.object_type,
        fileType: 'audio',
        locale: this.language
      }
      this.loading = true
      createStorage(params)
        .then(() => {
          this.loadAudios()
          this.loading = false
        })
        .catch(() => {
          this.loading = false
          this.$notify.error({
            title: 'Thông báo',
            message: 'Không thể upload audio: ' + fileInfo.file.name
          })
        })
    },

    deleteAudios(audio) {
      this.$confirm('Bạn có chắc chắn muốn xóa không?', 'Xác nhận', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Đóng',
        type: 'warning'
      }).then(() => {
        const ids = [audio.id]
        this.loading = true
        deleteStorages({
          file_type: 'audio',
          object_type: this.object_type,
          ids: ids.join(',')
        })
          .then(() => {
            this.audios = []
            this.loading = false
          })
          .catch(() => {
            this.loading = false
            this.$notify.error({
              title: 'Thông báo',
              message: 'Không thể xóa audio!'
            })
          })
      })
    },

    openUploadAudioPopup(audio) {
      this.selectedAudio = audio
      this.updateVisible = true
    }
  }
}
</script>

<style lang="scss">
.list-audios {
  .card-audio {
    .el-card__body {
      padding: 12px;
    }
  }
}
</style>
