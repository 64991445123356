<template>
  <el-form :key="info.key" class="editor-type" :model="data" label-position="top">
    <el-form-item :label="info.label" :prop="info.key">
      <editor
        :editor-key="info.value"
        :value="data[info.key]"
        @change="(v) => data[info.key] = v"
      />
    </el-form-item>
  </el-form>
</template>

<script>
import Editor from '../items/Editor.vue'

export default {
  name: 'EditorType',
  components: {
    Editor
  },
  props: {
    info: Object,
    data: Object
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss">
.editor-type {
  .ql-container {
    max-height: 300px;
    overflow: auto;
  }
  .ql-editor {
    color: #606266;
    font-size: 14px;
    font-family: inherit;
  }
}
</style>
