<template>
  <div class="pictures one-component-detail-admin">
    <div class="title-component-admin">
      <el-row type="flex" align="middle" justify="space-between">
        <span v-if="!checked">Hình ảnh</span>
        <span v-else>
          Chọn ảnh
          <span
            v-if="selectedImgs.length"
          >- Đã chọn {{ selectedImgs.length }} ảnh</span>
        </span>
        <div v-if="!checked">
          <el-button
            type="primary"
            size="small"
            @click="sortVisible = true"
          >Sắp xếp</el-button>
          <el-button
            type="primary"
            size="small"
            @click="checked = true"
          >Chọn</el-button>
          <el-upload
            class="avatar-uploader"
            accept="image/*"
            list-type="picture"
            multiple
            action
            :show-file-list="false"
            :http-request="uploadFiles"
          >
            <el-button
              type="primary"
              size="small"
              title="Chọn ảnh từ máy tính của bạn"
            >Thêm</el-button>
          </el-upload>
        </div>
        <div v-else>
          <el-button
            v-if="selectedImgs.length > 0"
            type="danger"
            size="small"
            @click="deletePictures(selectedImgs)"
          >Xóa</el-button>
          <el-button
            size="small"
            type="info"
            @click="removeChecked"
          >Hủy</el-button>
        </div>
      </el-row>
    </div>
    <div v-if="!images.length" v-loading="loading" class="empty p-3">
      Chưa có dữ liệu!
    </div>
    <el-row v-else type="flex" class="p-3">
      <div
        v-for="img in [...images]"
        :key="img.id"
        class="image mt-2"
        :class="{ checked: checked }"
        @click="handleSelectImg(img)"
      >
        <div v-if="!img.loading">
          <img :src="img.url" alt="href" />
          <i
            v-if="checked"
            title="Chọn ảnh"
            class="el-icon-success"
            :class="{ selected: img.checked }"
          ></i>
          <i
            v-if="!checked"
            class="el-icon-error remove-image text-danger"
            @click.stop="deletePictures([img])"
          ></i>
          <span
            v-if="!checked"
            title="Avartar"
            :class="{ 'is-avatar': isAvatar(img) }"
            @click.stop="handleSetAvatar(img)"
          >
            <i v-if="!checked" class="fas fa-user-circle"></i>
          </span>
        </div>
        <div v-else v-loading="true" class="loading"></div>
      </div>
    </el-row>
    <show-images-popup
      v-if="showVisible && !loading"
      :visible="showVisible"
      :selected-img="selectedImg"
      :images="images"
      @close="showVisible = false"
    />
    <sort-images-popup
      v-if="!loading"
      :visible="sortVisible"
      :images="images"
      :object-type="object_type"
      :object-id="data.id"
      @close="sortVisible = false"
      @update="(imgs) => (images = imgs)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  listStorages,
  createStorage,
  deleteStorages
} from '@/services/storage'
import ShowImagesPopup from '../popups/ShowImages.vue'
import SortImagesPopup from '../popups/SortImages.vue'

export default {
  name: 'Pictures',
  components: {
    ShowImagesPopup,
    SortImagesPopup
  },
  props: {
    data: Object,
    type: String
  },
  data() {
    return {
      images: [],
      loading: true,
      checked: false,
      showVisible: false,
      sortVisible: false,
      selectedImg: null
    }
  },
  computed: {
    ...mapGetters(['language']),
    object_type() {
      if (['commerce'].includes(this.type)) {
        return 'tour'
      }
      if (['tour', 'post', 'artifact', 'dish', 'dish_group'].includes(this.type)) {
        return this.type
      }
      return 'place'
    },
    selectedImgs() {
      return this.images.filter((i) => i.checked)
    }
  },
  beforeMount() {
    this.loadImages()
  },
  methods: {
    loadImages() {
      this.loading = true
      const params = {
        object_id: this.data.id,
        object_type: this.object_type,
        file_type: 'image',
        order: 'ordered',
        locale: this.language
      }
      listStorages(params)
        .then((res) => {
          this.images = res.result.map((img) => ({ ...img, checked: false }))
          if (['dish', 'dish_group'].includes(this.type)) {
            this.data.images = this.images
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    isAvatar(img) {
      return this.data.avatar_id === img.id || this.data.image_id === img.id
    },
    handleSelectImg(img) {
      if (this.checked) {
        img.checked = !img.checked
      } else {
        this.selectedImg = img
        this.showVisible = true
      }
    },
    removeChecked() {
      this.checked = false
      this.images = this.images.map((i) => ({ ...i, checked: false }))
    },
    uploadFiles(fileInfo) {
      this.images.push({
        checked: false,
        id: fileInfo.file.uid,
        loading: true
      })
      const params = {
        file: fileInfo.file,
        objectId: this.data.id,
        objectType: this.object_type,
        fileType: 'image',
        locale: this.language
      }
      createStorage(params)
        .then((res) => {
          this.images = this.images.map((img) => {
            return img.id === fileInfo.file.uid
              ? { ...res.data, checked: false }
              : img
          })
          this.data.images = this.images
          if (!this.images.find((img) => img.loading)) {
            this.loadImages()
          }
        })
        .catch(() => {
          this.images = this.images.filter(
            (img) => img.id !== fileInfo.file.uid
          )
          if (['dish', 'dish_group'].includes(this.type)) {
            this.data.images = this.images
          }
          this.$notify.error({
            title: 'Thông báo',
            message: 'Không thể upload file: ' + fileInfo.file.name
          })
        })
    },
    deletePictures(imgs) {
      this.$confirm('Bạn có chắc chắn muốn xóa không?', 'Xác nhận', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Đóng',
        type: 'warning'
      }).then(() => {
        const ids = imgs.map((i) => i.id)
        this.loading = true
        deleteStorages({
          file_type: 'image',
          object_type: this.object_type,
          ids: ids.join(',')
        })
          .then(() => {
            if (ids.includes(this.data.avatar_id)) {
              this.data.avatar_id = null
              this.data.avatar = null
            }
            this.images = this.images.filter((i) => !ids.includes(i.id))
            if (['dish', 'dish_group'].includes(this.type)) {
              this.data.images = this.images
            }
            this.loading = false
            this.checked = false
          })
          .catch(() => {
            this.loading = false
            this.$notify.error({
              title: 'Thông báo',
              message: 'Không thể xóa ảnh!'
            })
          })
      })
    },
    handleSetAvatar(img) {
      this.data.avatar_id = img.id
      if (['dish', 'dish_group'].includes(this.type)) {
        this.data.image_id = img.id
      }
      this.data.avatar = img
    }
  }
}
</script>

<style lang="scss" scoped>
.pictures {
  .el-row {
    flex-wrap: wrap;
    .image {
      padding-right: 15px;
      box-sizing: border-box;
      margin-bottom: 15px;
      width: 25%;
      position: relative;
      cursor: pointer;
      &.checked {
        padding: 0 5px;
      }
      .remove-image {
        position: absolute;
        top: -18px;
        right: 3px;
        font-size: 24px;
      }
      .el-icon-success {
        position: absolute;
        font-size: 28px;
        bottom: 8px;
        left: 8px;
        color: #dcdfe6 !important;
        &.selected {
          color: #409eff !important;
        }
      }
      svg {
        position: absolute;
        width: 28px;
        height: 28px;
        bottom: 10px;
        left: 8px;
        cursor: pointer;
        color: #dcdfe6 !important;
      }
      .is-avatar {
        svg {
          color: #67c23a !important;
        }
      }
      .loading {
        width: 100%;
        height: 120px;
        background-color: #dcdfe6;
        border: 1px solid #dcdfe6;
      }
    }
    img {
      width: 100%;
      height: 120px;
      object-fit: cover;
      z-index: 1;
    }
  }
}
</style>
