<template>
  <el-dialog
    top="80px"
    title="Sắp xếp hình ảnh theo thứ tự"
    class="sort-images-popup"
    width="740px"
    :visible="visible"
    :close-on-click-modal="false"
    append-to-body
    @close="$emit('close')"
  >
    <grid
      v-show="!setting"
      ref="grid_photo_sort"
      :draggable="true"
      :center="true"
      :sortable="true"
      :cell-width="cellWidth"
      :cell-height="cellHeight"
      :items="images"
      @sort="handleSort"
    >
      <template slot="cell" scope="props">
        <Icon :url="props.item.url" :index="props.index" :with-button="true" />
      </template>
    </grid>
    <el-row slot="footer" class="footer pt-3" type="flex" justify="end" align="middle">
      <el-button type="info" :disabled="callingAPI" @click="$emit('close')">Đóng</el-button>
      <el-button type="primary" :loading="callingAPI" @click="handleSave">Lưu thay đổi</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import Icon from '../items/Icon'
import { sortStorages } from '@/services/storage'

export default {
  name: 'SortImagesPopup',
  components: {
    Icon
  },
  props: {
    visible: Boolean,
    images: Array,
    objectType: String,
    objectId: String
  },
  data() {
    return {
      newImages: [],
      cellWidth: 100,
      cellHeight: 80,
      wrapWidth: 700,
      setting: true,
      callingAPI: false
    }
  },
  watch: {
    visible: 'setWidth'
  },
  beforeMount() {},
  methods: {
    handleSave() {
      this.callingAPI = true
      sortStorages({
        file_type: 'image',
        ids: this.newImages.map((i) => i.id),
        object_id: this.objectId,
        object_type: this.objectType
      }).then(() => {
        this.$notify.success({
          title: 'Thông báo',
          message: 'Sắp xếp ảnh thành công.'
        })
        this.callingAPI = false
        this.$emit('update', this.newImages)
        this.$emit('close')
      }).catch(() => {
        this.callingAPI = false
        this.$notify.error({
          title: 'Thông báo',
          message: 'Không thể cập nhật.'
        })
      })
    },
    handleSort(e) {
      const items = e.items.map((_item) => {
        return _item.item
      })
      this.newImages = items
    },
    setWidth() {
      if (this.visible) {
        this.setting = true
        setTimeout(() => {
          this.getSize()
          this.$nextTick(() => {
            this.setting = false
            window.addEventListener('resize', () => {
              this.getSize()
            })
          })
        }, 100)
      }
    },
    getSize() {
      const width = window.innerWidth
      if (width <= 768) {
        this.cellWidth = 100
        this.cellHeight = 80
        this.wrapWidth = width - 75
      } else {
        this.cellWidth = 140
        this.cellHeight = 100
        this.wrapWidth = 700
      }
      if (this.$refs.grid_photo_sort) {
        this.$refs.grid_photo_sort.windowWidth = this.wrapWidth
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.sort-images-popup {
  .footer {
    border-top: 1px solid #f5f5f5;
  }
}
</style>
<style lang="scss">
.sort-images-popup {
  .el-card__body {
    padding: 10px;
  }
}
</style>
