<template>
  <el-dialog
    top="80px"
    width="680px"
    class="create-artifact-popup"
    :title="`${!artifact ? 'Tạo mới ' : 'Cập nhật thông tin'}`"
    :visible="visible"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form
      ref="form"
      class="pt-2 pr-2"
      :model="form"
      :rules="rules"
      label-position="top"
    >
      <el-form-item label="Tên" prop="name">
        <el-input
          v-model="form.name"
          :disabled="callingAPI"
          placeholder="Nhập vào tên"
        ></el-input>
      </el-form-item>
      <el-form-item label="STT hiển thị" prop="ordered">
        <el-input
          v-model="form.ordered"
          :disabled="callingAPI"
          type="number"
          placeholder="Nhập stt hiển thị mong muốn"
        ></el-input>
      </el-form-item>
      <el-form-item label="Link Vrtour" prop="vrtour">
        <el-input
          v-model="form.vrtour"
          :disabled="callingAPI"
          placeholder="Nhập vào link"
        ></el-input>
      </el-form-item>
      <el-form-item label="Mô tả" prop="description">
        <el-input
          v-model="form.description"
          :rows="3"
          :disabled="callingAPI"
          type="textarea"
          placeholder="Nhập mô tả"
        ></el-input>
      </el-form-item>
      <el-form-item label="Thông tin nội dung" prop="content">
        <editor
          v-if="visible"
          editor-key="content"
          :value="form.content"
          @change="(v) => (form.content = v)"
        />
      </el-form-item>
      <el-form-item v-if="artifactInfo && visible" lang>
        <audios :data.sync="artifactInfo" type="artifact" />
        <pictures :data.sync="artifactInfo" type="artifact" />
        <videos :data.sync="artifactInfo" type="artifact" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClose">Hủy</el-button>
      <el-button
        v-if="!artifactInfo"
        type="primary"
        :loading="callingAPI"
        @click="handleCreateArtifact"
      >Tạo mới</el-button>
      <el-button
        v-else
        type="primary"
        :loading="callingAPI"
        @click="handleUpdateArtifact"
      >Cập nhật</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dayjs from 'dayjs'
import { mapGetters, mapActions } from 'vuex'
import Audios from '../cards/Audios.vue'
import Pictures from '../cards/Pictures.vue'
import Videos from '../cards/Videos.vue'
import Editor from '../items/Editor.vue'

import { createArtifact, updateArtifact } from '@/services/artifact'
import TYPE from '@/data/type'

export default {
  name: 'CreateArtifact',
  components: {
    Audios,
    Pictures,
    Editor,
    Videos
  },
  props: {
    visible: Boolean,
    artifact: Object,
    object: Object,
    type: String
  },
  data() {
    return {
      callingAPI: false,
      form: {
        name: '',
        ordered: '',
        vrtour: '',
        description: '',
        content: ''
      },
      artifactInfo: null,
      rules: {
        name: [
          { required: true, message: 'Tên không được để trống', trigger: ['blur', 'change'] }
        ],
        ordered: [
          { required: true, message: 'STT không được để trống', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['language']),
    placeType() {
      return TYPE.PLACES.find((p) => p.value === this.type)
    },
    payload() {
      let p = this.artifactInfo ? { ...this.artifactInfo } : { attraction_id: null }
      p = {
        ...p,
        ...this.form,
        locale: this.language
      }
      if (this.placeType.type === 'post') {
        return {
          ...p,
          post_id: this.object.id
        }
      }
      return {
        ...p,
        place_id: this.object.id
      }
    }
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    ...mapActions('artifact', ['updateReloadArtifactGroup']),
    loadData() {
      if (this.visible && this.artifact) {
        this.artifactInfo = this.artifact
        this.form = {
          name: this.artifact.name,
          ordered: this.artifact.ordered,
          vrtour: this.artifact.vrtour,
          description: this.artifact.description,
          content: this.artifact.content
        }
      } else {
        this.form = {
          name: '',
          ordered: '',
          vrtour: '',
          description: '',
          content: ''
        }
        this.artifactInfo = null
      }
    },
    handleCreateArtifact() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.callingAPI = true
          createArtifact(this.payload).then((response) => {
            this.callingAPI = false
            this.artifactInfo = response
            this.$notify.success({
              title: 'Thông báo',
              message: 'Tạo mới thành công'
            })
            this.$emit('reload')
          }).catch((error) => {
            this.callingAPI = false
            this.$notify.error({
              title: 'Thông báo',
              message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
            })
          })
        }
      })
    },
    handleUpdateArtifact() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.callingAPI = true
          updateArtifact(this.payload).then(() => {
            this.callingAPI = false
            this.handleClose()
            this.$notify.success({
              title: 'Thông báo',
              message: 'Cập nhật thành công'
            })
            this.$emit('reload')
            this.updateReloadArtifactGroup(dayjs().unix())
          }).catch((error) => {
            this.callingAPI = false
            this.$notify.error({
              title: 'Thông báo',
              message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
            })
          })
        }
      })
    },
    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.create-artifact-popup {
  .el-dialog__body {
    max-height: 550px;
    overflow: auto;
  }
}
</style>
