<template>
  <div class="artifact-groups one-component-detail-admin">
    <div class="title-component-admin">
      <el-row type="flex" align="middle" justify="space-between">
        <span>Nhóm danh sách ({{ artifactGroups.length }})</span>
        <div>
          <el-input
            v-model="newGroupName"
            size="small"
            placeholder="Nhập vào tên nhóm để thêm mới"
          ></el-input>
          <el-button
            type="primary"
            size="small"
            :disabled="loading || !newGroupName.trim()"
            @click="handleCreateGroup"
          >Thêm</el-button>
        </div>
      </el-row>
    </div>
    <div v-if="!artifactGroups.length" v-loading="loading" class="empty p-3">
      Chưa có nhóm!
    </div>
    <el-row v-else class="p-3">
      <div v-for="group in artifactGroups" :key="group.id" class="group mb-3">
        <el-row
          class="group__header p-2"
          type="flex"
          justify="space-between"
          align="middle"
        >
          <el-row
            v-if="editGroup && editGroup.id === group.id"
            class="m-0"
            type="flex"
            align="middle"
          >
            <el-input
              v-model="editGroup.summary"
              size="small"
              :disabled="callingAPI"
            ></el-input>
            <i
              class="ml-3 el-icon-check text-success"
              title="Lưu"
              @click="handleUpdateGroup"
            ></i>
            <i
              v-if="!callingAPI"
              class="ml-3 el-icon-close text-danger"
              title="Hủy"
              @click="editGroup = null"
            ></i>
          </el-row>
          <el-row v-else class="m-0" type="flex" align="middle">
            <b class="text-primary">{{ group.summary }}</b>
            <i
              class="ml-3 el-icon-edit text-primary"
              title="Sửa tên nhóm"
              @click="editGroup = JSON.parse(JSON.stringify(group))"
            ></i>
          </el-row>
          <div>
            <el-button
              type="primary"
              size="small"
              title="Quản lý danh sách trong nhóm"
              @click="handleOpenPopup(group)"
            >
              <i class="el-icon-edit"></i>
            </el-button>
            <el-button
              type="danger"
              size="small"
              title="Xóa nhóm"
              @click="handleDeleteGroup(group)"
            >
              <i class="el-icon-delete"></i>
            </el-button>
          </div>
        </el-row>
        <div class="group__content p-2">
          <div v-if="!group.artifacts.length" class="group__content--empty p-3">
            Chưa có dữ liệu!
          </div>
          <el-row class="group__content--items" :gutter="12">
            <el-col
              v-for="artifact in group.artifacts"
              :key="artifact.id"
              class="item mb-2"
              :lg="12"
            >
              <el-row type="flex" align="middle">
                <img
                  :src="
                    artifact.avatar && artifact.avatar.url
                      ? artifact.avatar.url
                      : imageDefaultUrl
                  "
                  class="mr-2"
                  alt="href"
                />
                <p class="text-limited m-0" :title="artifact.name">
                  {{ artifact.name }}
                </p>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-row>
    <artifact-group-popup
      v-if="formVisible"
      :visible="formVisible"
      :object="data"
      :type="type"
      :group="selectedGroup"
      @close="formVisible = false"
      @reload="getArtifactGroups"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  getClassifications,
  createClassification,
  updateClassification,
  deleteClassification
} from '@/services/artifact'
import ArtifactGroupPopup from '../popups/ArtifactGroup'

import TYPE from '@/data/type'

export default {
  name: 'ArtifactGroups',
  components: {
    ArtifactGroupPopup
  },
  props: {
    data: Object,
    type: String
  },
  data() {
    return {
      loading: true,
      callingAPI: false,
      artifactGroups: [],
      newGroupName: '',
      editGroup: null,
      imageDefaultUrl: '/default-image.jpeg',
      selectedGroup: null,
      selectedItem: null,
      formVisible: false
    }
  },
  computed: {
    ...mapGetters(['language', 'reloadArtifactGroup']),
    placeType() {
      return TYPE.PLACES.find((p) => p.value === this.type)
    },
    params() {
      if (this.placeType.type === 'post') {
        return {
          post_id: this.data.id,
          locale: this.language
        }
      }
      return {
        place_id: this.data.id,
        locale: this.language
      }
    },
    createPayload() {
      const payload = {
        artifact_ids: [],
        description: '',
        locale: this.language,
        summary: this.newGroupName
      }
      if (this.placeType.type === 'post') {
        return {
          ...payload,
          post_ids: [this.data.id]
        }
      }
      return {
        ...payload,
        place_id: this.data.id
      }
    },
    updatePayload() {
      const payload = {
        id: this.editGroup.id,
        artifact_ids: this.editGroup.artifacts.map((a) => a.id),
        description: '',
        locale: this.language,
        summary: this.editGroup.summary
      }
      if (this.placeType.type === 'post') {
        return {
          ...payload,
          post_ids: [this.data.id]
        }
      }
      return {
        ...payload,
        place_id: this.data.id
      }
    }
  },
  watch: {
    reloadArtifactGroup: 'getArtifactGroups'
  },
  beforeMount() {
    this.getArtifactGroups()
  },
  methods: {
    getArtifactGroups() {
      this.loading = true
      getClassifications(this.params).then((response) => {
        this.artifactGroups = response.result
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    handleOpenPopup(group) {
      this.selectedGroup = group
      this.formVisible = true
    },

    handleDeleteGroup(group) {
      this.$confirm('Bạn có chắc chắn muốn xóa không?', 'Xác nhận', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Đóng',
        type: 'warning'
      }).then(() => {
        this.loading = true
        deleteClassification(group.id)
          .then(() => {
            this.artifactGroups = this.artifactGroups.filter((g) => g.id !== group.id)
            this.loading = false
          })
          .catch(() => {
            this.loading = false
            this.$notify.error({
              title: 'Thông báo',
              message: 'Không thể xóa!'
            })
          })
      })
    },

    handleUpdateGroup() {
      if (this.handValidateName(this.editGroup.summary)) {
        this.callingAPI = true
        updateClassification(this.updatePayload).then(() => {
          this.callingAPI = false
          this.editGroup = null
          this.getArtifactGroups()
          this.$notify.success({
            title: 'Thông báo',
            message: 'Cập nhật nhóm thành công.'
          })
        }).catch(() => {
          this.callingAPI = false
          this.$notify.error({
            title: 'Thông báo',
            message: 'Không thể cập nhật nhóm.'
          })
        })
      }
    },

    handleCreateGroup() {
      if (this.handValidateName(this.newGroupName)) {
        this.callingAPI = true
        createClassification(this.createPayload).then(() => {
          this.callingAPI = false
          this.newGroupName = ''
          this.getArtifactGroups()
          this.$notify.success({
            title: 'Thông báo',
            message: 'Tạo nhóm mới thành công.'
          })
        }).catch(() => {
          this.callingAPI = false
          this.$notify.error({
            title: 'Thông báo',
            message: 'Không thể tạo nhóm mới.'
          })
        })
      }
    },

    handValidateName(name) {
      if (!name) {
        this.$notify.warning({
          title: 'Thông báo',
          message: 'Tên nhóm không được để trống.'
        })
        return false
      }
      const valid = !this.artifactGroups.find((g) => g.name === name)
      if (!valid) {
        this.$notify.warning({
          title: 'Thông báo',
          message: 'Tên nhóm đã tồn tại.'
        })
      }
      return valid
    }
  }
}
</script>

<style lang="scss" scoped>
.artifact-groups {
  .group {
    border: 1px solid #ebeef5;
    &__header {
      background-color: #ebeef5;
      i {
        font-weight: bold;
      }
    }
    &__content {
      &--empty {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &--items {
        .item {
          padding: 10px 20px;
          border-radius: 10px;
          img {
            width: 48px;
            height: 48px;
            object-fit: cover;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
</style>
