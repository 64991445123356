<template>
  <div class="address one-component-detail-admin">
    <div class="title-component-admin">Địa chỉ</div>
    <el-form :model="data" class="p-3">
      <el-form-item v-if="!['tour'].includes(type)" label="Nhập thông tin địa chỉ để tìm kiếm">
        <select-address :value="data.location" @input="(value) => data.location = value" />
      </el-form-item>
      <el-form-item v-if="!['tour'].includes(type)" label="Toạ độ bản đồ (Kinh độ - Vĩ độ)">
        <el-row :gutter="12" type="flex" class="w-100">
          <el-col>
            <el-input-number
              v-model="data.location.latitude"
              class="w-100"
              placeholder="Vĩ độ"
              :step="0.000000001"
            ></el-input-number>
          </el-col>
          <el-col>
            <el-input-number
              v-model="data.location.longitude"
              class="w-100"
              placeholder="Kinh độ"
              :step="0.000000001"
            ></el-input-number>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item v-if="type !== 'country'" label="Đất nước">
        <el-select v-model="data.country_id" filterable suffix-icon="fas fa-globe" clearable>
          <el-option v-for="c in countries" :key="c.id" :label="c.name" :value="c.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="!['country', 'area'].includes(type)" label="Thành phố">
        <el-select v-model="data.area_id" filterable suffix-icon="el-icon-place" clearable>
          <el-option v-for="a in areas" :key="a.id" :label="a.name" :value="a.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SelectAddress from '../items/Address.vue'

export default {
  name: 'Address',
  components: {
    SelectAddress
  },
  props: {
    data: Object,
    type: String
  },
  computed: {
    ...mapGetters(['constant', 'language']),
    areas() {
      return this.constant.constants.areas
    },
    countries() {
      return this.constant.constants.countries
    }
  }
}
</script>

<style lang="scss" scoped>
.address {
  .el-select {
    width: 100%;
  }
}
</style>
