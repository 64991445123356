<template>
  <div class="schedule one-component-detail-admin">
    <div class="title-component-admin">
      <el-row type="flex" align="middle" justify="space-between">
        <span>Lịch trình</span>
        <div>
          <el-button type="primary" size="small" @click="openScheduleForm(null)">Thêm</el-button>
        </div>
      </el-row>
    </div>
    <div v-if="!schedules.length" v-loading="loading" class="empty p-3">Chưa có lịch trình!</div>
    <div v-else class="p-3">
      <el-collapse accordion :value="selectedSchedule ? [selectedSchedule.id] : ''">
        <el-collapse-item
          v-for="(schedule, index) in schedules"
          :key="index"
          :name="schedule.id"
        >
          <template slot="title">
            <el-row type="flex" align="middle" justify="space-between">
              <span>
                <b class="mr-4">Ngày {{ index + 1 }}</b>
                <span>Thời gian: {{ schedule.time }}h - Khoảng cách: {{ schedule.distance }}km</span>
              </span>
              <div>
                <i
                  class="el-icon-plus mr-3 text-primary"
                  title="Thêm điểm đến"
                  @click.stop="openAddPlacePopup(schedule, null)"
                ></i>
                <i
                  class="el-icon-edit mr-3 text-primary"
                  title="Sửa"
                  @click.stop="openScheduleForm(schedule)"
                ></i>
                <i
                  class="el-icon-delete text-danger"
                  title="Xóa"
                  @click.stop="handleDeleteSchedule(schedule)"
                ></i>
              </div>
            </el-row>
          </template>
          <div v-html="schedule.description"></div>
          <div v-if="schedule.destinations.length">
            <p>
              <b>Danh sách điểm đến</b>
            </p>
            <el-row
              v-for="tDes in schedule.destinations"
              :key="tDes.id"
              type="flex"
              align="middle"
              justify="space-between"
              class="destinations mb-2"
            >
              <div>
                <p class="m-0">{{ tDes.place.name }}</p>
                <span>{{ tDes.description }}</span>
              </div>
              <div>
                <i
                  class="el-icon-edit mr-3 text-primary"
                  title="Sửa"
                  @click.stop="openAddPlacePopup(schedule, tDes)"
                ></i>
                <i
                  class="el-icon-delete text-danger"
                  title="Xóa"
                  @click.stop="handleDeleteTourDes(schedule, tDes)"
                ></i>
              </div>
            </el-row>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <schedule-form
      v-if="formVisible"
      :visible="formVisible"
      :data="selectedSchedule"
      @close="formVisible = false"
      @reload="getTourDays"
    ></schedule-form>
    <add-place
      v-if="addPlaceVisible"
      :visible="addPlaceVisible"
      :schedule="selectedSchedule"
      :tour="data"
      :data="tourDes"
      @close="addPlaceVisible = false"
      @reload="getTourDays"
    ></add-place>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getTourDays, deleteSchedule, deleteTourDes } from '@/services/tour'
import ScheduleForm from '../popups/Schedule.vue'
import AddPlace from '../popups/AddPlace.vue'

export default {
  name: 'Schedule',
  components: {
    ScheduleForm,
    AddPlace
  },
  props: {
    data: Object
  },
  data() {
    return {
      formVisible: false,
      loading: true,
      selectedSchedule: null,
      addPlaceVisible: false,
      schedules: [],
      tourDes: null
    }
  },
  computed: {
    ...mapGetters(['language']),
    params() {
      return {
        locale: this.language,
        tour_id: this.data.id
      }
    }
  },
  beforeMount() {
    this.getTourDays()
  },
  methods: {
    getTourDays() {
      this.loading = true
      getTourDays(this.params).then((response) => {
        this.schedules = response.result.reverse()
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    openScheduleForm(schedule) {
      this.selectedSchedule = schedule
      this.formVisible = true
    },

    openAddPlacePopup(schedule, tourDes) {
      this.selectedSchedule = schedule
      this.tourDes = tourDes
      this.addPlaceVisible = true
    },

    handleDeleteSchedule(schedule) {
      this.$confirm('Bạn có chắc chắn muốn xóa không?', 'Xác nhận', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Đóng',
        type: 'warning'
      }).then(() => {
        this.callingAPI = true
        deleteSchedule({ ids: schedule.id })
          .then(() => {
            this.schedules = this.schedules.filter((s) => s.id !== schedule.id)
            this.callingAPI = false
            this.$notify.success({
              title: 'Thông báo',
              message: 'Xóa thành công.'
            })
          })
          .catch(() => {
            this.callingAPI = false
            this.$notify.error({
              title: 'Thông báo',
              message: 'Không thể xóa!'
            })
          })
      })
    },

    handleDeleteTourDes(schedule, tDes) {
      this.$confirm('Bạn có chắc chắn muốn xóa không?', 'Xác nhận', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Đóng',
        type: 'warning'
      }).then(() => {
        this.callingAPI = true
        deleteTourDes({ ids: tDes.id })
          .then(() => {
            schedule.destinations = schedule.destinations.filter((d) => d.id !== tDes.id)
            this.callingAPI = false
            this.$notify.success({
              title: 'Thông báo',
              message: 'Xóa thành công.'
            })
          })
          .catch(() => {
            this.callingAPI = false
            this.$notify.error({
              title: 'Thông báo',
              message: 'Không thể xóa!'
            })
          })
      })
    }
  }
}
</script>

<style lang="scss">
.schedule {
  .el-collapse-item__header {
    .el-row {
      width: 100%;
    }
    .el-collapse-item__arrow {
      display: none;
    }
  }
  .el-collapse-item__content {
    .destinations {
      background: #ebeef5;
      border-radius: 10px;
      padding: 5px 15px;
    }
  }
}
</style>
