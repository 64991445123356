<template>
  <div class="link-youtube one-component-detail-admin">
    <div class="title-component-admin">Link video youtube</div>
    <div class="p-3">
      <el-input
        v-model="link"
        placeholder="Nhập link youtube"
        @keyup.native.enter="handleAddLink"
      >
        <el-button slot="append" @click="handleAddLink">
          <i class="el-icon-plus"></i>
        </el-button>
      </el-input>
      <el-row
        v-if="data.youtube_links && data.youtube_links.length"
        class="list-link pt-3"
        :gutter="24"
      >
        <el-col
          v-for="(l, index) in data.youtube_links"
          :key="index"
          :lg="8"
          class="mt-2"
        >
          <el-button
            type="danger"
            class="remove-link"
            circle
            size="mini"
            @click="handleRemoveLink(l)"
          >
            <i class="el-icon-close"></i>
          </el-button>
          <iframe :src="l" frameborder="0" height="150px" width="100%"></iframe>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Youtube',
  props: {
    data: Object
  },
  data() {
    return {
      link: ''
    }
  },
  methods: {
    handleAddLink() {
      if (this.link) {
        var regExp =
          /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
        if (this.link.match(regExp)) {
          if (this.data.youtube_links.find((l) => l === this.link)) {
            this.$notify.warning({
              title: 'Thông báo',
              message: 'Link youtube đã tồn tại.'
            })
          } else {
            const link = this.link.replace('watch?v=', 'embed/').split('&')[0]
            this.data.youtube_links.push(link)
          }
          this.link = ''
        } else {
          this.$notify.warning({
            title: 'Thông báo',
            message: 'Link youtube không đúng định dạng.'
          })
        }
      } else {
        this.$notify.warning({
          title: 'Thông báo',
          message: 'Chưa nhập link youtube.'
        })
      }
    },

    handleRemoveLink(link) {
      this.data.youtube_links = this.data.youtube_links.filter(
        (l) => l !== link
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.link-youtube {
  .list-link {
    .el-col {
      position: relative;
      .remove-link {
        position: absolute;
        right: 0%;
        top: -5%;
      }
    }
  }
}
</style>
