<template>
  <div class="parent one-component-detail-admin">
    <div class="title-component-admin">Tour du lịch mẫu</div>
    <el-form :model="data" class="p-3">
      <el-form-item label="">
        <el-select
          v-model="tourIds"
          class="w-100"
          filterable
          disabled
          multiple
          remote
        >
          <el-option v-for="p in tours" :key="p.id" :label="p.name" :value="p.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { listTours } from '@/services/tour'

export default {
  name: 'Tour',
  props: {
    data: Object,
    type: String
  },
  data() {
    return {
      searchText: '',
      searching: false,
      tours: [],
      placeType: '',
      tourIds: []
    }
  },
  computed: {
    ...mapGetters(['constant', 'language']),
    params() {
      return {
        locale: this.language,
        place_id: this.data.id,
        per_page: 10,
        search_text: this.searchText
      }
    }
  },
  beforeMount() {
    this.getTours(this.params)
  },
  methods: {
    getTours(params) {
      this.searching = true
      const request = listTours(params)
      request.then((res) => {
        this.tours = res.result
        this.tourIds = res.result.map((r) => r.id)
        this.searching = false
      }).catch(() => {
        this.searching = false
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
